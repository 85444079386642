@import 'app.scss';
@keyframes onmount-fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes onmount-fade-in-content {
  from {
    transform: translateX(-2rem);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.hero {
  position: relative;
  background-color: var(--color-white);
  color: var(--hero-fg-color);

  /* START: Color variants */
  &.brand {
    --hero-bg-color: var(--color-red-500);
    --hero-fg-color: var(--color-white);
  }

  &.gray {
    --hero-bg-color: var(--color-bg-body);
    --hero-fg-color: var(--color-fg-body);
  }

  &.primary {
    --hero-bg-color: var(--color-bg-primary);
    --hero-fg-color: var(--color-fg-primary);
  }

  &.secondary {
    --hero-bg-color: var(--color-bg-secondary);
    --hero-fg-color: var(--color-fg-secondary);
  }

  /* END: Color variants */

  &.noImage {
    .content {
      padding-top: spacing(20);
    }

    &.small {
      .content {
        padding-top: spacing(4);
      }
    }

    .wrapper {
      @include until-breakpoint(md) {
        padding-right: var(--panel-item-size);
      }
    }
  }
}

.mediaContainer {
  position: relative;
  animation: onmount-fade-in ease 1 forwards;
  animation-duration: var(--onmount-animation-duration);
}

.content {
  background-color: var(--hero-bg-color);
  padding-top: var(--section-vertical-spacing);
  padding-bottom: var(--section-vertical-spacing);
}

.topBar {
  display: flex;
  flex-wrap: wrap;
  grid-column: 1 / -1;
  gap: spacing(2);
  align-items: center;
  justify-content: space-between;
  margin-bottom: spacing(2);

  .narrow & {
    @include from-breakpoint(md) {
      grid-column: 2 / span 10;
    }

    @include from-breakpoint(lg) {
      grid-column: 4 / span 8;
    }
  }
}

.title {
  margin-bottom: 0;
}

.text {
  @include typography-paragraph-md;

  margin-top: spacing(4);
  margin-bottom: 0;

  @include from-breakpoint(md) {
    @include typography-paragraph-lg;

    font-weight: 400;
  }
}

.children {
  margin-top: spacing(8);
}

.tags {
  margin-top: spacing(2);
}

.cta {
  margin-top: spacing(6);
}

.container {
  @include container-width;

  .narrow & {
    .wrapper {
      @include from-breakpoint(md) {
        grid-column: 2 / span 10;
      }

      @include from-breakpoint(lg) {
        grid-column: 4 / span 6;
      }
    }

    @include from-breakpoint(md) {
      @include container-grid;
    }
  }
}

.buttonsContainer {
  display: grid;
  grid-auto-flow: column;
  gap: spacing(3);
  margin-left: auto;
}

.ctaWrapper {
  display: flex;
  flex-wrap: wrap;
  column-gap: spacing(4);
}

/* START: Hero variants */

.hero.small {
  .content {
    padding-bottom: spacing(10);
  }

  .mediaContainer {
    width: calc(100% - var(--panel-item-size));
    height: 18.125rem; // 290px (design)

    @include from-breakpoint(md) {
      height: 45lvh; // 400px
      min-height: calc(
        4 * var(--panel-item-size)
      ); // https://esites.atlassian.net/browse/BE-113
    }
  }
}

.hero.large {
  display: flex;
  flex-direction: column;

  .content {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: flex-end;
    order: -1;
    z-index: 1;
    padding-top: calc(var(--panel-item-size) + spacing(4));
    width: calc(100% - var(--panel-item-size));
    min-height: calc(6 * var(--panel-item-size));
    animation-name: onmount-fade-in-content;

    @include from-breakpoint(lg) {
      justify-content: center;
      background-color: transparent;
      width: 100%;
      min-height: 40rem;

      &::after {
        position: absolute;
        top: 0;
        left: 0;
        background-color: var(--hero-bg-color);
        width: var(--large-hero-content-width);
        height: calc(100% + spacing(12));
        animation: onmount-fade-in-after ease 1 forwards;
        animation-duration: var(--onmount-animation-duration);
        content: '';
      }
    }
  }

  .mediaContainer {
    margin-top: spacing(-8);
    width: 100%;
    height: 16.25rem; // 260px (design)

    @include from-breakpoint(md) {
      height: 20rem; // 320px
    }

    @include from-breakpoint(lg) {
      --image-container-spacing: calc(
        (100% - var(--max-container-width)) / 2 + var(--max-hero-content-width) +
          var(--horizontal-container-padding)
      );

      position: absolute;
      top: 0;
      left: var(--image-container-spacing);
      margin-top: 0;
      width: calc(
        100% - var(--image-container-spacing) - var(--panel-item-size)
      );
      height: 100%;
    }
  }

  .wrapper {
    animation: onmount-fade-in-content ease 1 forwards;
    animation-duration: var(--onmount-animation-duration);

    @include from-breakpoint(lg) {
      position: relative;
      z-index: 1;
      max-width: var(--max-hero-content-width);
    }
  }

  @include from-breakpoint(lg) {
    justify-content: center;

    + [data-section] {
      padding-top: calc(var(--section-vertical-spacing) + spacing(12));
    }
  }
}

.hero.search {
  .content {
    padding-top: calc(var(--section-vertical-spacing) + var(--panel-item-size));
    padding-bottom: var(--section-vertical-spacing);
  }
}

.warningContainer {
  background-color: var(--color-bg-brand);
  color: var(--color-white);
}

.warningWrapper {
  display: flex;
  align-items: center;
  height: 3rem;
}

.warningText {
  grid-column: 1/-1;
  margin: 0;
}

/* END: Hero variants */

[data-theme='BREDA_STRAALT'] {
  .hero {
    --hero-bg-color: var(--color-breda-straalt);
    --hero-fg-color: var(--color-white);
  }

  .cta {
    --bg-color: var(--color-bg-brand);
    --bg-color-hover: var(--color-bg-brand-hover);
  }
}
